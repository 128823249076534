<!-- 服务费模板 -->
<template>
  <div>
    <table-inner v-loading="loading.list" :table-header="false" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :page-id="tableList.pageId" @changePageNum="changeExpandPageNum">
      <template slot="table-columns">
        <el-table-column prop="useCode" label="用信编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="serviceChargeCode" label="服务费编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="serviceChargeAmount" label="服务费金额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        <el-table-column prop="capName" label="资方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="coreName" label="核心企业企业" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="channelName" label="运营方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="createdAt" label="生成时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="verifyStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'verifyStatus')" show-overflow-tooltip min-width="150" />
        <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
          <template slot-scope="scope">
            <a href="javascript:" class="text-btn" @click="$router.push({ name: 'serviceChargeRecordDetail', query: { id: scope.row.id } })">详情</a>
          </template>
        </el-table-column>
      </template>
    </table-inner>
    <ics-common-upload-inner :upload-dialog="exportDialog" bucket="other" @onSuccessFile="onSuccessFile" />
  </div>
</template>
<script>
import IcsCommonUploadInner from '@/components/common-upload-inner'
class FormProp {
  id = ''
  fileList = []
}
export default {
  name: 'RecordList',
  components: {IcsCommonUploadInner},
  props: {
    searchForm: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      formInfo: new FormProp(),
      searchFormExpand: {
        pageNum: '',
        month: '',
        useCode: '',
        serviceChargeCode: '',
        capName: '',
        coreName: '',
        channelName: ''
      },
      tableList: {},
      loading: {
        list: false,
        submit: false
      },
      exportDialog: {
        title: '',
        visible: false
      },
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      }
    }
  },
  mounted () {
    console.log(this.searchFormExpand)
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.serviceCharge.getByMonth(this.utils.filterTrimValue(this.searchForm)).then(res => {
        this.tableList = res.data.data
      }).finally(() => {
        // 解放loading
        this.loading.list = false
      })
    },
    changeExpandPageNum (val) {
      this.searchForm.pageNum = val.pageNum
      this.getList()
    },
    addExport (id) {
      this.exportDialog.title = '上传核销文件'
      this.formInfo.id = id
      this.exportDialog.visible = true
    },
    onSuccessFile(response) {
      this.api.serviceCharge.verify(this.formInfo.id, response.data.path).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('保存成功')
          this.exportDialog.visible = false
          this.getList()
        } else {
          this.loading.submit = false
          this.$message.error('保存失败')
        }
      }).finally(() => {
        this.loading.submit = false
      })
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:100%;
  }
</style>
